import * as ko from 'knockout';
import 'knockout.validation';

export class PfaResultPageViewModel {
    public isSubmitting: KnockoutObservable<boolean> = ko.observable(false);

    constructor() {
        const form = document.getElementById('acceptAndApplyForm');
        form.addEventListener('submit', () => {
            this.isSubmitting(true);
        });
    }
}
