import {
    ICallQualityFeedbackDto,
    ICallQualityFeedbackRequest,
} from '../../Common/interfaces';
import { Texts } from '../../Common/Texts';
import { Utils } from '../../Common/Utils';
import { AlertViewModel } from '../AlertViewModel';

export class CallQualityFeedback {
    public qualityOk: KnockoutObservable<boolean>;
    public qualityBad: KnockoutObservable<boolean>;
    public qualityBadSound: KnockoutObservable<boolean>;
    public qualityBadVideo: KnockoutObservable<boolean>;
    public qualityBadConnection: KnockoutObservable<boolean>;
    public qualityNoConnection: KnockoutObservable<boolean>;
    public qualityBadUserInterface: KnockoutObservable<boolean>;
    public qualityBadOther: KnockoutObservable<boolean>;
    public qualityDescription: KnockoutObservable<string>;
    private consultationUid: string;
    private videoSessionUid: string;
    private azureCallId: string;
    private alertModal: AlertViewModel;
    private isSaving: boolean;

    constructor(feedback: ICallQualityFeedbackDto) {
        const self = this;

        self.consultationUid = feedback.ConsultationUid;
        self.videoSessionUid = feedback.VideoSessionUid;
        self.azureCallId = feedback.AzureCallId;

        self.alertModal = new AlertViewModel();
        self.isSaving = false;

        self.qualityOk = ko.observable(false);
        self.qualityBad = ko.observable(true);
        self.qualityBadSound = ko.observable(false);
        self.qualityBadVideo = ko.observable(false);
        self.qualityBadConnection = ko.observable(false);
        self.qualityNoConnection = ko.observable(false);
        self.qualityBadUserInterface = ko.observable(false);
        self.qualityBadOther = ko.observable(false);

        self.qualityDescription = ko.observable('');
    }

    public saveFeedback(): void {
        const self = this;

        if (self.isSaving) {
            // Guard against double submits
            return;
        }
        self.isSaving = true;

        const request: ICallQualityFeedbackRequest = {
            QualityOk: self.qualityOk(),
            QualityBad: self.qualityBad(),
            QualityBadSound: self.qualityBadSound(),
            QualityBadVideo: self.qualityBadVideo(),
            QualityBadConnection: self.qualityBadConnection(),
            QualityNoConnection: self.qualityNoConnection(),
            QualityBadUserInterface: self.qualityBadUserInterface(),
            QualityBadOther: self.qualityBadOther(),
            QualityDescription: self.qualityDescription(),
            AzureCallId: self.azureCallId,
        };

        const callBackAjax = (
            response: any,
            status: string,
            jqXhr: JQueryXHR,
        ): void => {
            window.location.href = window.location.origin + '/doctor';
        };

        const errorCallBack = (
            jqXhr: JQueryXHR,
            textStatus: string,
            error: string,
        ): void => {
            self.isSaving = false;
            self.alertModal.openAlert(
                Texts.getResource('ErrorCallQualityFeedback'),
            );
        };

        Utils.ajaxCall(
            'POST',
            'api/doctors/calls/' +
                self.consultationUid +
                '/feedback/' +
                self.videoSessionUid,
            callBackAjax,
            errorCallBack,
            JSON.stringify(request),
        );
    }
}
