import './Common/sentry';

const w = window as any;

import jquery from 'jquery';
w.$ = w.jQuery = jquery;

import './knockout/KnockoutExtenders';
import './knockout/KnockoutValidationExtenders';

import * as ko from 'knockout';
w.ko = ko;

import Dropzone from 'dropzone';
Dropzone.autoDiscover = false;
w.Dropzone = Dropzone;

import { ProfileViewModel } from './ViewModels/ProfileViewModel';
import { Utils } from './Common/Utils';

import { setDefaultOptions } from 'date-fns';
import { da, enGB } from 'date-fns/locale';

const cultureQuery = Utils.getParameterByName('culture');
const cultureCookie = decodeURIComponent(
    Utils.getCookie('.AspNetCore.Culture'),
);

const formatRelativeLocale: any = {
    lastWeek: Texts.get('dateFnsLongDateTimeFormat'),
    yesterday: Texts.get('dateFnsLongDateTimeFormat'),
    today: Texts.get('dateFnsTodayRelativeFormat'),
    tomorrow: Texts.get('dateFnsTomorrowRelativeFormat'),
    nextWeek: Texts.get('dateFnsLongDateTimeFormat'),
    other: Texts.get('dateFnsLongDateTimeFormat'),
};

if (cultureQuery == 'en' || cultureCookie == 'c=en|uic=en') {
    const locale = {
        ...enGB,
        formatRelative: (token: string) => formatRelativeLocale[token],
    };
    setDefaultOptions({ locale: locale });
} else {
    const locale = {
        ...da,
        formatRelative: (token: string) => formatRelativeLocale[token],
    };
    setDefaultOptions({ locale: locale });
}

import {
    ConsultationTypeEnum,
    ShouldPayConsultationTypeEnum,
} from './Common/enums';
import { BookEmailConsultationViewModel } from './ViewModels/consultations/BookEmailConsultationViewModel';
import { BookVideoConsultationViewModel } from './ViewModels/consultations/BookVideoConsultationViewModel';
import { StepProgress } from './ViewModels/StepProgress';
import { Log } from './Common/Log';
import { Heartbeat } from './Common/heartbeat';
import { AlertViewModel } from './ViewModels/AlertViewModel';
import { RateConsultation } from './ViewModels/consultations/RateConsultationViewModel';
import { ReceiveVideoCall } from './ViewModels/consultations/ReceiveVideoCallFromDoctor';
import { ProfileInsuranceViewModel } from './ViewModels/ProfileInsuranceViewModel';
import { ConsultationDetailsViewModel } from './ViewModels/consultations/ConsultationDetailsViewModel';
import { CallQualityFeedback } from './ViewModels/doctor/CallQualityFeedbackViewModel';
import { SelectPatientViewModel } from './ViewModels/consultations/SelectPatientViewModel';
import { ClockValidatorApp } from './features/clockValidator/ClockValidatorApp';
import { Texts } from './Common/Texts';
import { PfaResultPageViewModel } from './ViewModels/PfaResultPageViewModel';

w.Hejdoktor = {
    ProfileViewModel: ProfileViewModel,
    Utils: Utils,
    ShouldPayConsultationTypeEnum: ShouldPayConsultationTypeEnum,
    ConsultationTypeEnum: ConsultationTypeEnum,
    BookEmailConsultationViewModel: BookEmailConsultationViewModel,
    BookVideoConsultationViewModel: BookVideoConsultationViewModel,
    StepProgress: StepProgress,
    Log: Log,
    AlertViewModel: AlertViewModel,
    RateConsultation: RateConsultation,
    ReceiveVideoCall: ReceiveVideoCall,
    ProfileInsuranceViewModel: ProfileInsuranceViewModel,
    ConsultationDetailsViewModel: ConsultationDetailsViewModel,
    Heartbeat: Heartbeat,
    CallQualityFeedback: CallQualityFeedback,
    SelectPatientViewModel: SelectPatientViewModel,
    ClockValidatorApp: ClockValidatorApp,
    PfaResultPageViewModel: PfaResultPageViewModel,
};
